import * as React from 'react';
import * as _ from 'underscore';
import { MaximumConfigurationNameLength } from '../../../../../ApplicationConfiguration';
import { IConfiguration } from '../../../../../Models/IConfiguration';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../../LocalizedComponent';
import { IConfigurationEntry } from '../../DefinitionSelection/IConfigurationEntry';
import { DataEntryMode } from './DataEntryMode';
import { IConfigurationObject } from './IConfigurationObject';
import { NumericField } from './NumericField';

export interface IConfigurationFieldSetProps extends ILocalizedComponentProps {
  update: (newValue: IConfiguration, index: number) => void;
  updateValidity: (index: number, newValue: boolean) => void;
  remove: (index: number) => void;
  configurationMode: DataEntryMode;
  configurationObject: IConfigurationObject;
  index: number;
  configuration: IConfiguration;
}

export interface IConfigurationFieldSetState {
  valueEntry: string;
}

const UNIT_KIND_PATH = 'set_configurations.unit_kind';
const UNIT_ENTRY_PATH = 'set_configurations.unit_entry';
const VALUE_PATH = 'set_configurations.value';
const NAME_PATH = 'set_configurations.name';

export class ConfigurationFieldSet extends LocalizedPureComponent<
  IConfigurationFieldSetProps,
  IConfigurationFieldSetState
> {
  public constructor(props: IConfigurationFieldSetProps, context?: any) {
    super(props, context);
    this.state = {
      valueEntry: props.configuration.value.toString(),
    };
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IConfigurationFieldSetProps>,
    nextContext: any,
  ): void {
    if (nextProps.configuration.value !== this.props.configuration.value) {
      this.setState({ valueEntry: nextProps.configuration.value.toString() });
    }
  }

  public render(): JSX.Element {
    return this.props.configurationMode === DataEntryMode.Input
      ? this.renderEntryMode()
      : this.renderConfigureMode();
  }

  private renderConfigureMode(): JSX.Element {
    return (
      <div className="config-FieldGroup horizontal-group">
        <div className="form-horizontal-container config-UnitKind">
          {this.renderUnitKind()}
          <label>{this.localize(UNIT_KIND_PATH)}</label>
        </div>
        {this.renderUnitEntrySection()}
        <div className="form-horizontal-container config-Name">
          <input
            className="form-field exercise-set-configuration-name"
            value={this.props.configuration.name}
            maxLength={MaximumConfigurationNameLength}
            onChange={this.handleConfigNameChange}
          />
          <label>{this.localize(NAME_PATH)}</label>
        </div>
        <div className="standard-button config-removeParameter" onClick={this.handleRemoveClick}>
          <i className="icon-cancel" />
        </div>
      </div>
    );
  }

  private renderEntryMode(): JSX.Element {
    let specificationUnit: string;
    if (this.props.configuration.unit_kind === 'custom') {
      specificationUnit = this.props.configuration.unit_entry;
    } else {
      const unitEntries = this.props.configurationObject.unitEntries[
        this.props.configuration.unit_kind
      ];
      const unitEntryText = _.find(
        unitEntries,
        value => value.key === this.props.configuration.unit_entry,
      );
      specificationUnit = unitEntryText.title;
    }
    return (
      <div className="config-FieldGroup horizontal-group">
        <div className="form-horizontal-container config-Entry">
          <NumericField
            className="exercise-set-configuration-value form-field"
            updateValue={this.handleConfigValueBlur}
            name="exercise_set[configuration][][value]"
            value={this.props.configuration.value}
          />
          <label>{this.localize(VALUE_PATH)}</label>
        </div>
        <div className="form-horizontal-container config-Entry config-Unit">
          {specificationUnit} {this.props.configuration.name}
        </div>
        <div className="standard-button config-removeParameter" onClick={this.handleRemoveClick}>
          <i className="icon-cancel" />
        </div>
      </div>
    );
  }

  private handleConfigNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.updateConfig('name', event.target.value);
  };

  private handleConfigValueBlur = (value: number): void => {
    this.updateConfig('value', value);
  };

  private handleRemoveClick = (): void => {
    this.props.remove(this.props.index);
  };

  // noinspection JSMethodCanBeStatic
  private renderConfigurationOptions(optionSet: IConfigurationEntry[]): JSX.Element[] {
    return optionSet.map(entry => {
      return (
        <option label={entry.title} value={entry.key} key={entry.key}>
          {entry.title}
        </option>
      );
    });
  }

  private updateConfig(propertyChanged: keyof IConfiguration, newValue: any) {
    const config = _.clone(this.props.configuration);
    (config[propertyChanged] as any) = newValue;
    if (config.unit_kind !== 'custom') {
      config.unit_entry = this.coerceUnitEntry(config.unit_kind, config.unit_entry);
    }
    this.props.update(config, this.props.index);
    this.props.updateValidity(this.props.index, true);
  }

  // <editor-fold desc="Unit Entry and Kind Methods">

  private renderUnitKind(): JSX.Element {
    const unitKinds = this.renderConfigurationOptions(this.props.configurationObject.unitKinds);
    return (
      <select
        name="exercise_set[performance_specifications][][unit_kind]"
        onChange={this.handleUnitKindChange}
        className="exercise-set-specification-unit-kind"
        value={this.props.configuration.unit_kind}
      >
        {unitKinds}
      </select>
    );
  }

  private renderUnitEntrySection(): JSX.Element {
    const unitEntries = this.renderUnitEntries();
    if (unitEntries == null) {
      return null;
    }
    return (
      <div className="form-horizontal-container specification-config">
        {unitEntries}
        <label>{this.localize(UNIT_ENTRY_PATH)}</label>
      </div>
    );
  }

  private renderUnitEntries(): JSX.Element {
    if (this.props.configuration.unit_kind === 'custom') {
      return (
        <input
          className="form-field exercise-set-config-unit-entry-custom"
          name="exercise_set[performance_specifications][][unit_entry]"
          onChange={this.handleUnitEntryChange}
          value={this.props.configuration.unit_entry}
        />
      );
    }
    const unitEntries = this.props.configurationObject.unitEntries[
      this.props.configuration.unit_kind
    ];
    const unitEntryOptions = this.renderConfigurationOptions(unitEntries);
    if (unitEntries.length <= 1) {
      return null;
    }
    return (
      <select
        name="exercise_set[performance_specifications][][unit_entry]"
        onChange={this.handleUnitEntryChange}
        value={this.props.configuration.unit_entry}
        className="exercise-set-config-unit-entry-standard"
      >
        {unitEntryOptions}
      </select>
    );
  }

  private handleUnitKindChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.updateConfig('unit_kind', event.target.value);
  };

  private handleUnitEntryChange = (
    event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.updateConfig('unit_entry', event.target.value);
  };

  private coerceUnitEntry(newKind: string, currentEntry: string): string {
    const permittedValues = this.props.configurationObject.unitEntries[newKind];
    // If Current Entry in permitted Values keys, return as-is. Otherwise, return first kind.
    const isPermitted = _.any(permittedValues, entry => entry.key === currentEntry);
    return isPermitted ? currentEntry : permittedValues[0].key;
  }

  // </editor-fold>
}
