import * as React from 'react';
import * as _ from 'underscore';
import { IExercise } from '../../../../Models/IExercise';
import { Workout } from '../../../../Models/Workout';
import { INewSelectionViewModel } from '../../../../ViewModels/Selection/INewSelectionViewModel';
import { LocalizationContext } from '../../../LocalizedComponent';
import { CommandsSection } from '../Shared/CommandsSection';
import { InstructionsContainer } from '../Shared/InstructionsContainer';
import { NoteElement } from '../Shared/NoteElement';
import { Title } from '../Shared/Title';
import { DateContainer } from './DateContainer';
import { ExerciseList } from './ExerciseList';

export interface IWorkoutSelectionProps {
  viewModel: INewSelectionViewModel;
  workout: Workout;
  isVisible: boolean;
  changeSpinnerVisibility: (newValue: boolean) => void;
}

export interface IWorkoutSelectionState {
  reminderEditing: boolean;
  reminderNotes: string;
  deleteVisible: boolean;
}

export class WorkoutSelection extends React.Component<
  IWorkoutSelectionProps,
  IWorkoutSelectionState
> {
  public constructor(props: IWorkoutSelectionProps) {
    super(props);
    const noteContent = props.workout ? props.workout.note_content : '';
    this.state = {
      deleteVisible: false,
      reminderEditing: false,
      reminderNotes: noteContent,
    };
  }

  public render(): JSX.Element {
    if (!_.isObject(this.props.workout)) {
      return <div />;
    }
    return (
      <div>
        <Title title={this.props.workout.name} labelClass="sv-workout-name" />
        <div className="msv-section">
          <DateContainer
            label={this.context.getValue('object.workout.planned_date')}
            date={this.props.workout.planned_date}
          />
          {this.props.workout.instructions && (
            <InstructionsContainer
              label={this.localize('object.workout.instructions')}
              inputClass="sv-workout-instructions"
              contents={this.props.workout.instructions}
            />
          )}
        </div>
        <NoteElement
          label={this.localize('object.workout.note_content')}
          contents={this.state.reminderNotes}
          isEditing={this.state.reminderEditing}
          onChange={this.handleChange}
          startEditing={this.startEditing}
          cancelEditing={this.cancelEditing}
          applyChanges={this.applyChanges}
          inputClass={'sv-workout-review'}
        />
        <CommandsSection
          viewModel={this.props.viewModel}
          deletePromptVisible={this.state.deleteVisible}
          updateDeleteVisibility={this.updateDeleteVisibility}
          executeDelete={this.executeDelete}
          deleteDeniedVisible={false}
          deleteCancel={this.localize('mobile_selection_view.delete_workout_confirm.cancel')}
          deleteConfirm={this.localize('mobile_selection_view.delete_workout_confirm.confirm')}
          deleteWarning={this.localize('mobile_selection_view.delete_workout_confirm.warning')}
        >
          <button className="xbtn xbtn-primary sv-copy" onClick={this.handleOpenCopyWorkout}>
            {this.localize('mobile_selection_view.copy_workout')}
          </button>
        </CommandsSection>
        <ExerciseList exercises={this.exercises()} />
      </div>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IWorkoutSelectionProps>,
    nextContext: any,
  ): void {
    this.updateNotes(nextProps.workout.note_content);
  }

  private handleOpenCopyWorkout = (): void => {
    this.props.viewModel.openCopyWorkout();
  };

  private exercises(): IExercise[] {
    const exerciseGroups = this.props.workout.children();
    return _.flatten(_.map(exerciseGroups, eg => eg.children()));
  }

  private updateNotes(newNoteValue: string) {
    this.setState({ reminderNotes: newNoteValue });
  }

  private updateDeleteVisibility = (newValue: boolean): void => {
    this.setState({ deleteVisible: newValue });
  };

  private localize(path: string): string {
    return this.context.getValue(path);
  }

  private executeDelete = (): void => {
    this.props.changeSpinnerVisibility(true);
    this.setState({ deleteVisible: false });
    const promise = this.props.viewModel.deleteObject();
    promise.always(() => this.props.changeSpinnerVisibility(false));
  };

  private applyChanges = (): void => {
    this.props.changeSpinnerVisibility(true);
    const promise = this.props.viewModel.updateNoteContent(this.state.reminderNotes);
    promise
      .always(() => this.props.changeSpinnerVisibility(false))
      .done(() => this.setState({ reminderEditing: false }));
  };

  private handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ reminderNotes: event.target.value });
  };

  private startEditing = (): void => {
    if (this.state.reminderEditing !== true) {
      this.setState({ reminderEditing: true });
    }
  };

  private cancelEditing = (): void => {
    this.setState({
      reminderEditing: false,
      reminderNotes: this.props.workout.note_content,
    });
  };
}

WorkoutSelection.contextType = LocalizationContext;
