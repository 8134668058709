import * as React from 'react';

export interface ILastUpdateTimerProps {
  lastUpdatedAt: string;
}

export interface ILastUpdateTimerState {
  renderedDuration: string;
  renderedTime: string;
}

export class LastUpdateTimer extends React.PureComponent<
  ILastUpdateTimerProps,
  ILastUpdateTimerState
> {
  private intervalId: number;

  public constructor(props: ILastUpdateTimerProps, context?: any) {
    super(props, context);
    this.state = this.renderTime(props.lastUpdatedAt);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<ILastUpdateTimerProps>,
    nextContext: any,
  ): void {
    if (nextProps.lastUpdatedAt !== this.props.lastUpdatedAt) {
      this.updateTime(nextProps.lastUpdatedAt);
    }
  }

  public componentDidMount(): void {
    this.intervalId = window.setInterval(() => this.updateTime(), 10000);
  }

  public componentWillUnmount(): void {
    window.clearInterval(this.intervalId);
  }

  public render(): JSX.Element {
    return (
      <div className="sv-data">
        Last updated {this.state.renderedDuration} {this.state.renderedTime}
      </div>
    );
  }

  private updateTime(lastUpdatedAt?: string): void {
    lastUpdatedAt = lastUpdatedAt || this.props.lastUpdatedAt;
    const renderedTime = this.renderTime(lastUpdatedAt);
    this.setState(renderedTime);
  }

  private renderTime(lastUpdatedAt: string): ILastUpdateTimerState {
    const lastUpdatedMoment = moment(lastUpdatedAt);
    const renderedTime = lastUpdatedMoment.isAfter(moment().startOf('day'))
      ? lastUpdatedMoment.format('[at] h:mm a')
      : '';
    return {
      renderedDuration: lastUpdatedMoment.fromNow(),
      renderedTime,
    };
  }
}
